import React, { useEffect } from "react"
import AppStoreLogo from "../images/app-store.svg"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import SimpleContent from "../components/sections/SimpleContent"
import Layout from "../components/Layout"
import { CircularProgress } from "@material-ui/core"

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageBox = styled.div`
  width: 160px;
`

const AppPage: React.FC = () => {
  const { strapi } = useStaticQuery(
    graphql`
      query {
        strapi {
          appPage {
            content {
              content
              align
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              featuredImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const content = strapi?.appPage?.content

  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent

      if (/android/i.test(userAgent)) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.sens.ai"
      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.location.href =
          "https://apps.apple.com/ca/app/sens-ai-brain-training/id1612297335"
      }
      setIsLoading(false)
    }
  }, [])

  return isLoading ? (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  ) : (
    <Layout>
      <div>
        <SimpleContent {...content} />
      </div>
      <Container>
        <a href="https://apps.apple.com/ca/app/sens-ai-brain-training/id1612297335">
          <ImageBox>
            <AppStoreLogo />
          </ImageBox>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.sens.ai&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <ImageBox>
            <img
              style={{ width: "100%", height: "100%" }}
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </ImageBox>
        </a>
      </Container>
    </Layout>
  )
}

export default AppPage
